import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getQRCode, setQRCode } from "./qrcode";
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";

axios.defaults.withCredentials = true;

const initialState = {
  amount: null,
  firstName: null,
  lastName: null,
  sessionData: null,
  signedIn: false,
};

const { actions, reducer } = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.signedIn = true;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.amount = action.payload.amount;
      return state;
    },
    logout: () => {
      return initialState;
    },
    update: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.amount = action.payload.amount;
      return state;
    },
    setSessionData: (state, action) => {
      state.sessionData = action.payload
      return state;
    }
  },
});

export const { setUser, logout, update, setSessionData } = actions;
export default reducer;

// export const BASE_URL_API = "http://localhost:8080/api/v1/";
// export const BASE_URL_API = "http://192.168.2.12:8080/api/v1/";
export const BASE_URL_API = "https://haystack-demo-435919.uk.r.appspot.com/api/v1/";

export const fetchSessionId = (sessionData) => {
  return axios.get(`${BASE_URL_API}user/get-sid`, {
    headers: {
      withCredentials: true
    }
  })
  .then(response => {
    return response.data.sessionId;
  })
  .catch(err => {
    console.log(err.message);
    // throw err;
  });
};

/**
 * Create new user in the ddb
 * @param { object} e Event informations
 * @param { object } store Redux store
 * @param { Function } navigate 'navigate' of useNavigate() from react-router-dom
 * @returns { ( store | Error) } If 'Connect me' is checked, user is login, else navigate to sign-in page
 */
export async function signUp(e, store, navigate) {
  e.preventDefault();
  const email = e.target[0].value;
  const password = e.target[1].value;
  const firstName = capitalizeFirstLetter(e.target[2].value);
  const lastName = capitalizeFirstLetter(e.target[3].value);
  // const connect = e.target[4].checked;
  const submitButton = document.getElementsByClassName("sign-up-button")[0];
  // const errorText = document.getElementsByClassName("errorText")[0];

  // Reset animation
  submitButton.style.animation = "";
  try {
    const response = await axios.post(`${BASE_URL_API}user/signup`, {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
    });
    
    const qrcode = response.data.body;
    
    setQRCode(qrcode);
    
    const qrCodeData = await getQRCode();
    
    localStorage.setItem("qrcode", qrCodeData);
    return qrcode;
  } catch (error) {}
}

/**
 * Logs the user into the application
 * @param { object} e Event informations
 * @param { object } store Redux store
 * @returns { ( store | Error) } Store token in redux, if 'Remember me' is checked, email is store in the local storage
 */
// function downloadTextFile(data, filename) {
//   const blob = new Blob([data], { type: "text/plain" });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.style.display = "none";
//   a.href = url;
//   a.download = filename;
//   document.body.appendChild(a);
//   a.click();
//   window.URL.revokeObjectURL(url);
// }
export function Login(e, { email, password, token }, setError, store) {
  e.preventDefault();
  const sessionData = store.getState().user.sessionData;
  axios
    .post(`${BASE_URL_API}user/login`, {
      email,
      password,
      token,
    },
    {
      headers: {
        'Authorization': `Bearer ${sessionData}`
      }
    })
    .then((response) => {
      localStorage.setItem("isLogged", true);
      const user = response.data.body.user;
      store.dispatch(setUser(user));
    })
    .catch(() => {
      setError('Authentication Failed')
    });
}

/**
 * Get user details
 * @param { object } store Redux store
 * @returns { ( store | Error) } Store user details in redux
 */
export function getUser(store) {
  const sessionData = store.getState().user.sessionData;
  axios
    .post(
      `${BASE_URL_API}user/profile`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${sessionData}`
        }
      }
    )
    .then((response) => {
      const body = response.data.body;
      const payload = {
        firstName: body.firstName,
        lastName: body.lastName,
        amount: body.amount,
      };
      store.dispatch(update(payload));
    })
    .catch((error) => {
      console.log("Error with getUser =>", error);
    });
}
/**
 * Update user details in ddb
 * @param { object } store Redux store
 * @param { Function } setEdit set 'edit' state from useState
 * @returns { ( store | Error) } Store new user details in redux
 */
export function updateUser(store, setEdit) {
  const first = document.getElementById("first-name");
  const last = document.getElementById("last-name");
  const saveButton = document.getElementById("saveButton");
  const errorText = document.getElementsByClassName("errorText")[0];

  const isValid = first.checkValidity() && last.checkValidity();

  const firstName = capitalizeFirstLetter(first.value);
  const lastName = capitalizeFirstLetter(last.value);

  const sessionData = store.getState().user.sessionData;

  if (isValid) {
    axios
      .put(
        `${BASE_URL_API}user/profile`,
        {
          firstName: firstName,
          lastName: lastName,
        },
        {
          headers: {
            'Authorization': `Bearer ${sessionData}`
          }
        }
      )
      .then((response) => {
        const body = response.data.body;
        const payload = {
          firstName: body.firstName,
          lastName: body.lastName,
          amount: body.amount,
        };
        store.dispatch(update(payload));
        setEdit(false);
      })
      .catch((error) => {
        console.log("Error with updateUser =>", error);
      });
  } else {
    saveButton.classList.add("errorButton");
    saveButton.style.animation =
      "shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both";
    errorText.classList.remove("hide");
  }
}

/**
 * Update user details in ddb
 * @param { object } store Redux store
 * @returns { ( store | Error) } Store new user details in redux
 */

export function transferFunds(store, amountToSend) {
  const sessionData = store.getState().user.sessionData;
  return axios
    .put(
      `${BASE_URL_API}user/transaction`,
      {
        amount: amountToSend,
      },
      {
        headers: {
          'Authorization': `Bearer ${sessionData}`
        }
      }
    )
    .then((response) => {
      const body = response.data;
      const payload = {
        firstName: body.firstName,
        lastName: body.lastName,
        amount: body.amount,
      };
      store.dispatch(update(payload));

      return response;
    })
}
