import React from 'react';
import { AppBar, Toolbar, InputBase, IconButton, Badge, Avatar, Typography, Box } from '@mui/material';
import { Search as SearchIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { user } from "../../app/selectors";
import { Link } from 'react-router-dom';

const styles = {
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: 'black'
  },
  searchInput: {
    borderRadius: '20px',
    backgroundColor: '#f0f0f0',
    '&:hover': {
      backgroundColor: '#e8e8e8',
    },
    width: '100%',
    padding: '5px 20px',
  },
  iconButton: {
    padding: '10px',
  },
  avatar: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: '10px',
    color: 'inherit', // Here we ensure that the color is consistent
  }
};

const HeaderToolbar = () => {
  const { firstName, lastName } = useSelector(user);
  return (
    <AppBar position="static" color="default" sx={styles.appBar}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <InputBase
            startAdornment={<SearchIcon fontSize="small" />}
            placeholder="Search…"
            sx={styles.searchInput}
          />
        </Box>
        <IconButton size="large" sx={styles.iconButton}>
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
          <Link to="/user" style={{ textDecoration: 'none', color: 'inherit' }}> {/* Remove underline */}
        <Box sx={styles.avatar}>
            <Avatar src="https://img.freepik.com/free-photo/young-attractive-businesswoman_93675-134188.jpg" alt={`${firstName} ${lastName}`} />
            <Typography variant="subtitle1" sx={styles.userName}>
              {`${firstName} ${lastName}`}
            </Typography>
        </Box>
          </Link>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderToolbar;
