import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog({
  open,
  onClose,
  onSubmit,
  children
}) {

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle variant='h5'>Transfer Funds</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "200px" }}>
          <DialogContentText>
            Please select a recepient and input an amount
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions sx={{ padding: "0 25px 25px 25px" }}>
          <Button sx={{ color: "#2ECEF2", borderColor: "#2ECEF2" }} variant='outlined' onClick={onClose}>Cancel</Button>
          <Button sx={{ color: "white", backgroundColor: "#2ECEF2" }} className="account-page-primary-btn" variant='contained' onClick={onSubmit}>Transfer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
