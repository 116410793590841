import * as React from 'react';
import { Dialog, Select, MenuItem, FormControl, InputLabel, TextField, IconButton, DialogContent, DialogActions, Button, Box, DialogTitle, DialogContentText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Importing Add Icon
import FormDialog from '../Dialog';

export function TransferDialog({
  sendAmount,
  setSendAmount,
  recepient,
  setRecepient,
  onSubmit,
  onClose,
  open,
}) {
  const [openNewRecipientDialog, setOpenNewRecipientDialog] = React.useState(false);
  const [newRecipientName, setNewRecipientName] = React.useState('');
  const [newRecipientEmail, setNewRecipientEmail] = React.useState('');
  const [recipients, setRecipients] = React.useState([
    "John Doe", "Abraham Carnagie", "Linda Carson", "Frederick Woods"
  ]);

  const handleOpenNewRecipientDialog = () => {
    setOpenNewRecipientDialog(true);
  };

  const handleCloseNewRecipientDialog = () => {
    setOpenNewRecipientDialog(false);
  };

  const handleAddNewRecipient = () => {
    setRecipients([...recipients, newRecipientName]);
    handleCloseNewRecipientDialog();
  };

  return (
    <>
      <FormDialog
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '80%', border: 'none' }} variant="filled" fullWidth>
              <InputLabel>Recipient</InputLabel>
              <Select value={recepient} onChange={(e) => setRecepient(e.target.value)}>
                {recipients.map((name, index) => (
                  <MenuItem key={index} value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton sx={{ width: '18%', height: '100%', color: "#2ECEF2", borderColor: "#2ECEF2" }} onClick={handleOpenNewRecipientDialog}>
              <AddIcon />
            </IconButton>
          </Box>
          <TextField value={sendAmount} onChange={(e) => setSendAmount(e.target.value)} variant="filled" label="Amount" type='number' sx={{ width: '100%', border: "none" }}></TextField>
        </>
      </FormDialog>

      <Dialog open={openNewRecipientDialog} onClose={handleCloseNewRecipientDialog}>
        <DialogTitle variant='h5'>Add a Recepient</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "200px" }}>
          <DialogContentText>
            Please enter the users full name and email
          </DialogContentText>
          <TextField
            label="Full Name" 
            variant='filled'
            value={newRecipientName} 
            onChange={(e) => setNewRecipientName(e.target.value)} 
            fullWidth 
          />
          <TextField 
            label="Email" 
            variant='filled'
            value={newRecipientEmail} 
            onChange={(e) => setNewRecipientEmail(e.target.value)} 
            fullWidth 
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0 25px 25px 25px" }}>
          <Button sx={{ color: "#2ECEF2", borderColor: "#2ECEF2" }} variant='outlined' onClick={handleCloseNewRecipientDialog}>Cancel</Button>
          <Button sx={{ color: "white", backgroundColor: "#2ECEF2" }} className="account-page-primary-btn" variant='contained' onClick={handleAddNewRecipient}>Add Recipient</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
