// dataCollector.js

function collectBrowsingData() {
  const data = {
    browser: getBrowserName(),
    browser_version: getBrowserVersion(),
    user_agent: navigator.userAgent,
    languages: navigator.languages,
    platform_type: getPlatformType(),
    os_family: getOSFamily(),
    os_version: getOSVersion(),
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    color_depth: window.screen.colorDepth,
    time_zone: -new Date().getTimezoneOffset(),
    plugins: getPlugins(),
    fonts: [], // Font detection is limited due to browser restrictions
    ip: "", // To be filled by the server if needed
    // Add more data points as required
  };

  return data;
}

// Helper functions to get browser, OS, etc.
function getBrowserName() {
  const userAgent = navigator.userAgent;
  if (/Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor)) {
    return "Chrome";
  }
  // Add more browser detections as needed
  return "Other";
}

function getBrowserVersion() {
  const userAgent = navigator.userAgent;
  const match = userAgent.match(/Chrome\/(\d+)/);
  return match ? match[1] : "";
}

function getPlatformType() {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  return isMobile ? 2 : 1; // 1 for Desktop, 2 for Mobile
}

function getOSFamily() {
  const platform = navigator.platform.toLowerCase();
  if (platform.includes("win")) return "Windows";
  if (platform.includes("mac")) return "Mac OS X";
  if (platform.includes("linux")) return "Linux";
  return "Other";
}

function getOSVersion() {
  // Implement OS version detection if possible
  return "";
}

function getPlugins() {
  return navigator.plugins
    ? Array.from(navigator.plugins).map((plugin) => ({
        name: plugin.name,
        filename: plugin.filename,
        description: plugin.description,
      }))
    : [];
}

export default collectBrowsingData;
