import collectBrowsingData from '../data/dataCollector';

async function sendDataToTrackingServer(actionType, additionalData = {}) {
  const browsingData = collectBrowsingData();
  const geolocationData = await getGeolocationData();

  const payload = {
    actionType,
    timestamp: Date.now(),
    dataPoints: {
      ...browsingData,
      ...geolocationData,
      ...additionalData,
    },
  };
  
  fetch('https://banking-sim-tracking-backend-dot-haystack-demo-435919.uk.r.appspot.com/track', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).catch(error => {
    console.error('Error sending data to tracking server:', error);
  });
}

function getGeolocationData() {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude, accuracy } = position.coords;
          resolve({ latitude, longitude, accuracy });
        },
        error => {
          console.error('Geolocation error:', error);
          resolve(null); // Resolve with null if permission is denied or an error occurs
        },
        {
          enableHighAccuracy: true, // Use GPS if available
          timeout: 5000, // 5 seconds
          maximumAge: 0,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      resolve(null);
    }
  });
}

export default sendDataToTrackingServer;
