import PropTypes from "prop-types";
import { useState } from "react";
import { transferFunds } from "../../features/user";
import { user } from "../../app/selectors";
import { store } from "../../app/store";
import { useSelector } from "react-redux";
import {
  Chip,
  Grid,
  Card,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Button,
  FormControl, InputLabel, MenuItem, Select,
  TextField,
  Snackbar,
  Alert
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import PaidIcon from '@mui/icons-material/Paid';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DataGrid } from "@mui/x-data-grid";
import logo from "../../assets/stock-phone-image.png";
import Dialog from "../Dialog";
import { TransferDialog } from "../TransferDialog";

function DataTable() {
  function getStatusColor(status) {
    if (status === "COMPLETED") return "#7ED957";
    if (status === "PENDING") return "#FF914D";
    if (status === "CANCEL") return "#FF5757";
    return "default";
  }

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip label={params.value} sx={{ color: 'white', fontWeight: 'bold', backgroundColor: getStatusColor(params.value) }} />
      ),
    },
    { field: "date", headerName: "Date", flex: 1 },
  ];

  const rows = [
    {
      id: 1,
      name: "John Doe",
      date: "August 15, 2023",
      amount: 300,
      status: "COMPLETED",
    },
    {
      id: 2,
      name: "Alice Johnson",
      date: "August 16, 2023",
      amount: 7600,
      status: "PENDING",
    },
    {
      id: 3,
      name: "Emma Wilson",
      date: "August 17, 2023",
      amount: 540,
      status: "COMPLETED",
    },
    {
      id: 4,
      name: "Noah Harris",
      date: "August 18, 2023",
      amount: 950,
      status: "CANCEL",
    },
    {
      id: 5,
      name: "Oliver Smith",
      date: "August 19, 2023",
      amount: 1200,
      status: "COMPLETED",
    },
    {
      id: 6,
      name: "Isabella Brown",
      date: "August 20, 2023",
      amount: 3200,
      status: "PENDING",
    },
    {
      id: 7,
      name: "Ethan Davis",
      date: "August 21, 2023",
      amount: 430,
      status: "COMPLETED",
    },
    {
      id: 8,
      name: "Mia Rodriguez",
      date: "August 22, 2023",
      amount: 8500,
      status: "CANCEL",
    },
    {
      id: 9,
      name: "Jacob Martinez",
      date: "August 23, 2023",
      amount: 2300,
      status: "COMPLETED",
    },
    {
      id: 10,
      name: "Sophia Gonzalez",
      date: "August 24, 2023",
      amount: 7100,
      status: "PENDING",
    },
  ];

  return (
    <Card style={{ height: 'auto', width: "100%" }}>
      <Box>
        <Typography variant="h5" sx={{ textAlign: 'left', padding: "20px 0 20px 20px" }}>Transfers</Typography>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        pageSize={5}
      />
    </Card>
  );
}

function AccountContent({ title, description }) {
  const [sendAmount, setSendAmount] = useState("");
  const [recepient, setRecepient] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarVariant, setSnackBarVariant] = useState('success');

  const { amount } = useSelector(user);

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSendMoney = async () => {
    const amountToSend = parseFloat(sendAmount);
    if (!isNaN(amountToSend) && amountToSend > 0 && amountToSend < amount) {
      transferFunds(store, amountToSend)
      .then(() => {
        setSnackBarVariant('success')
        setSnackBarMessage(`$${amountToSend} successfully transferred to ${recepient}`)
        setOpenSnackbar(true);
        setSendAmount("");
        handleCloseModal()
      })
      .catch(() => {
        setSnackBarVariant('error')
        setSnackBarMessage(`Failed attempted transfer to ${recepient}`)
        setOpenSnackbar(true);
      });
    } else {
      alert("Please enter a valid positive amount to send.");
    }
  };

  return (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackBarVariant} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <TransferDialog 
        sendAmount={sendAmount} 
        setSendAmount={setSendAmount}
        recepient={recepient}
        setRecepient={setRecepient}
        onSubmit={handleSendMoney}
        onClose={handleCloseModal}
        open={openModal}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card className="top-row-card">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box sx={{ height: "70%", padding: "20px" }}>
                <Typography component="div" variant="h5">
                  Balance
                </Typography>
                <Typography variant="h2" color="text.secondary" component="div">
                  ${(amount).toLocaleString()}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  height: "30%",
                }}
              >
                <Button className="account-page-primary-btn" size="large" variant="contained">
                  Withdraw
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className="top-row-card">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box sx={{ height: "70%", padding: "20px" }}>
                <Typography component="div" variant="h5">
                  Last Transfer
                </Typography>
                <Typography variant="h2" color="text.secondary" component="div">
                  ${(150).toLocaleString()}
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  height: "30%"
                }}
              >
                <Button className="account-page-primary-btn-outlined" onClick={handleOpenModal} size="large" variant="outlined">
                  Click to Transfer
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="top-row-card">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                height: "100%",
              }}
            >
              <Box sx={{ height: "70%", padding: "20px" }}>
                <Typography
                  component="div"
                  variant="body"
                  sx={{ marginBottom: "10px" }}
                >
                  RECOMMENDED
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: "10px" }}
                >
                  We highly encourage setting up an authenticator app.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleOutlineIcon color="success" />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    component="div"
                    sx={{ marginLeft: '5px' }}
                  >
                    Add safe and secure account access
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                  height: "30%",
                }}
              >
                <Button className="account-page-primary-btn" size="large" variant="contained">
                  Get Set Up
                </Button>
                <Button
                  sx={{ marginLeft: "20px" }}
                  size="large"
                  variant="outlined"
                  className="account-page-primary-btn-outlined"
                >
                  Dismiss
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                width: "30%",
                height: "100%",
              }}
            >
              <img className="image-contained" src={logo} alt="phone" />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="mid-row-card">
            <List
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: "column",
              }}
              subheader={
                <ListSubheader
                  component="div"
                  sx={{ textAlign: 'left', padding: "20px 0 20px 20px" }}
                >
                  <Typography variant="h5" color="black">Quick Access</Typography>
                </ListSubheader>
              }
            >
              <ListItemButton
                sx={{ flexGrow: 1, display: "flex", alignItems: "center", border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', margin: '5px 10px' }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "purple" }}>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">Add Accounts and Services</Typography>
                  }
                  secondary={
                    <Typography color="text.secondary" variant="body">
                      Create additional accounts to manage your funds at no additional cost
                    </Typography>
                  }
                />
              </ListItemButton>
              <ListItemButton
                sx={{ flexGrow: 1, display: "flex", alignItems: "center", border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', margin: '5px 10px' }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "orange" }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      Update personal details
                    </Typography>
                  }
                  secondary={
                    <Typography color="text.secondary" variant="body">
                      Update your personal info
                    </Typography>
                  }
                />
              </ListItemButton>
              <ListItemButton
                sx={{ flexGrow: 1, display: "flex", alignItems: "center", border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '10px', margin: '5px 10px' }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "green" }}>
                    <LocationSearchingIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">Track Spending</Typography>
                  }
                  secondary={
                    <Typography color="text.secondary" variant="body">
                      View your montly spending trends
                    </Typography>
                  }
                />
              </ListItemButton>
            </List>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="mid-row-card" sx={{ display: "flex", flexDirection: 'column', paddingBottom: '10px' }}>
            <Box sx={{ textAlign: "left", padding: "20px 0 20px 20px" }}>
                <Typography variant="h5" color="text2">Services</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around", height: "85%"}}>
              <Box className="pending-tasks" sx={{ backgroundColor: 'rgba(253, 227, 167, 0.3)' }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                    padding: "10px"
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "orange",
                      width: 80,
                      height: 80,
                      "& svg": {
                        fontSize: "3rem",
                      },
                    }}
                  >
                    <CreditCardIcon />
                  </Avatar>
                  <Box>
                    <Typography gutterBottom variant="h5" component="div">
                      Credit Card
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Choose from a variety of credit cards that best suit your lifestyle.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="pending-tasks" sx={{ backgroundColor: 'rgba(103, 242, 209, 0.3)' }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                    padding: "10px"
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#4ecdc4",
                      width: 80,
                      height: 80,
                      "& svg": {
                        fontSize: "3rem",
                      },
                    }}
                  >
                    <SavingsIcon />
                  </Avatar>
                  <Box>
                    <Typography gutterBottom variant="h5" component="div">
                      Open Savings
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Start a savings account with us and watch your money grow.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="pending-tasks" sx={{ backgroundColor: 'rgba(251, 231, 239, 0.5)' }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                    padding: "10px"
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "pink",
                      width: 80,
                      height: 80,
                      "& svg": {
                        fontSize: "3rem",
                      },
                    }}
                  >
                    <PaidIcon />
                  </Avatar>
                  <Box>
                    <Typography gutterBottom variant="h5" component="div">
                      Apply for Loan
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Explore loan options with competitive rates and easy repayment plans.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <DataTable />
        </Grid>
      </Grid>
    </>
  );
}
// return (
//   <div className="account-content">
//     <div>
//       <h3 className="account-title">Argent Bank {title}</h3>
//       <p className="account-amount">${amount}</p>
//       <p className="account-amount-description">{description} Balance</p>
//     </div>
//     <div className="money">
//       <label htmlFor="money"></label>
//       <input
//         type="number"
//         id="money"
//         value={sendAmount}
//         onChange={(e) => setSendAmount(e.target.value)}
//         placeholder="Enter Amount"
//       />
//       <button onClick={handleSendMoney} className="sign-up-button">
//         Send Money
//       </button>
//     </div>
//   </div>
// );

AccountContent.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  description: PropTypes.string,
};

export default AccountContent;
