import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";

import { useStore, useSelector } from "react-redux";
import { user } from "../../app/selectors";
import logo from "../../assets/gl-logo.png";
import phoneIcon from "../../assets/sign-in-page-icon.png";

import { Login, fetchSessionId, setSessionData } from "../../features/user";
import sendDataToTrackingServer from '../../features/trackingClient';

import { Grid, Box, Typography, TextField, Button, Snackbar, Alert } from "@mui/material";

function SignIn() {
  // const theme = useTheme();
  const store = useStore();
  const { signedIn, sessionData } = useSelector(user);

  const navigate = useNavigate();
  localStorage.removeItem("qrcode");

  // State to manage the visibility of box-1 and box-2
  const [displaySignIn, setDisplaySignIn] = useState(true);
  const [displayQR, setDisplayQR] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setError(false);
  };

  useEffect(() => {
    fetchSessionId().then((sessionId) => {
      store.dispatch(setSessionData(sessionId));
      sendDataToTrackingServer('LOGIN', {
        sessionId
      });
      // window.cdapi.setCustomerSessionId(sessionId);
      // window.cdapi.changeContext("LOGIN");
    })
  }, [store]);
  
  useEffect(() => {
    signedIn && navigate("/account");
  }, [signedIn, navigate]);

  const handleEmailPasswordSubmit = () => {
    setDisplaySignIn(false);
    setDisplayQR(true);
  };

  const handleErrors = (errorMessage) => {
    setErrorMessage(errorMessage);
    setError(true);
    setDisplayQR(false);
    setDisplaySignIn(true);
    setEmail('');
    setPassword('');
    setToken();
  }

  const handle2FASubmit = (e) => {
    e.preventDefault();

    Login(e, { email, password, token }, handleErrors, store);
  };

  return (
    <Grid id="SignIn" container>
      <Grid item xs={4} paddingLeft={6} paddingRight={0} paddingY={6} sx={{ backgroundColor: 'primary.main', color: 'white', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: '15%', width: '100%' }}>
          <Link to="/">
            <img id='sign-in-logo' src={logo} alt="Argent Bank Logo" />
          </Link>
        </Box>
        <Box sx={{ height: '75%', display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
              Welcome to
            </Typography>
            <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
              Good Financial
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: '100', fontSize: '43px' }}>
              The safest fake banc in the world
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} sx={{ backgroundColor: 'primary.main', color: 'white', display: 'flex', flexDirection: 'column', paddingTop: '120px', paddingLeft: '20px' }}>
        <img src={phoneIcon} alt="phone icon" />
      </Grid>
      <Grid item xs={5} sx={{ backgroundColor: 'primary.main', color: 'white', padding: '0 10% 0 2%' }}>
        <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Box sx={{ height: '59%', width: '100%' }}>
            {
              displaySignIn &&
              <>
                {/* <Box sx={{ height: '15%' }}>
                  <Typography fontWeight='bold' variant="h4">Sign in</Typography>
                </Box> */}
                <form onSubmit={handleEmailPasswordSubmit}>
                  <Box sx={{ height: '55%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <TextField value={email} onChange={(e) => setEmail(e.target.value)} variant="filled" label="Email" type='email' sx={{ border: 'none', width: '100%', backgroundColor: 'background.paper' }}></TextField>
                      <Link to={'/'}><Typography sx={{ marginTop: '10px', marginBottom: '15px' }}>Forgot email</Typography></Link>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <TextField value={password} onChange={(e) => setPassword(e.target.value)} variant="filled" label="Password" type='password' sx={{ border: 'none', width: '100%', backgroundColor: 'background.paper' }}></TextField>
                      <Link to={'/'}><Typography sx={{ marginTop: '10px' }}>Forgot password</Typography></Link>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Button sx={{ paddingX: '60px', paddingY: '10px', width: '50%', textTransform: 'none', fontSize: '20px' }} color='secondary' type="submit" size="large" variant="contained">Sign In</Button>
                      <Typography sx={{ marginTop: '15px', fontSize: '1.2rem' }}>Don't have an online account? <Link to={'/sign-up'}>Sign up</Link></Typography>
                    </Box>
                  </Box>
                </form>
              </>
            }
            {
              displayQR && 
              <>
                <Box sx={{ height: '15%' }}>
                  <Typography fontWeight='bold' variant="h4">2 Factor Authentication</Typography>
                </Box>
                <form onSubmit={handle2FASubmit}>
                  <Box sx={{ height: '65%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <TextField value={token} onChange={(e) => setToken(e.target.value)} variant="filled" label="Token" type='number' sx={{ width: '100%', backgroundColor: 'background.paper' }}></TextField>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Button sx={{ paddingX: '60px', paddingY: '10px', width: '50%', textTransform: 'none', fontSize: '20px' }} color='secondary' type="submit" size="large" variant="contained">Verify</Button>
                    </Box>
                  </Box>
                </form>
              </>
            }
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SignIn;
