import { NavLink, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { user } from "../../app/selectors";
import { BASE_URL_API, logout } from "../../features/user";
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faSignOut,
  faSignIn,
  faBuildingColumns,
  faArrowTrendUp,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/GoodLabs-Logo.png";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Toolbar, Typography } from "@mui/material";

const drawerWidth = 350;

function Header() {
  const { signedIn } = useSelector(user);
  const dispatch = useDispatch();

  function signOut() {
    axios.get(`${BASE_URL_API}user/sign-out`, 
      {
        withCredentials: true
      },
    )
    .then((response)=> {
        dispatch(logout())
    })
    .catch((error) => {
        console.log(error);
    })
  }

  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
        variant="permanent"
        anchor="left"
        className="header-nav"
      >
        <Box>
          <Toolbar>
            <Link className="main-nav-logo" to="/">
              <img src={logo} alt="Good Banc Logo" />
            </Link>
          </Toolbar>
          {signedIn &&
                  <List>
                    <ListItem disablePadding>
                      <NavLink 
                        to="/account" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                      >
                        <ListItem button>
                        <ListItemIcon className="nav-item-icon">
                          <FontAwesomeIcon icon={faMoneyBill} className="margin" />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: '2rem' }}>Account</Typography>
                        </ListItem>
                      </NavLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <NavLink 
                        to="/invest" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                      >
                        <ListItem button>
                        <ListItemIcon className="nav-item-icon">
                          <FontAwesomeIcon icon={faBuildingColumns} className="margin" />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: '2rem' }}>Invest</Typography>
                        </ListItem>
                      </NavLink>
                    </ListItem>
                    <ListItem disablePadding>
                      <NavLink 
                        to="/markets" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                      >
                        <ListItem button>
                        <ListItemIcon className="nav-item-icon">
                          <FontAwesomeIcon icon={faArrowTrendUp} className="margin" />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: '2rem' }}>Markets</Typography>
                        </ListItem>
                      </NavLink>
                    </ListItem>
                </List>
              } 
        </Box>
        {signedIn ?
                    <ListItem disablePadding>
                      <NavLink 
                        to="/" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                        onClick={signOut}
                      >
                        <ListItem button>
                        <ListItemIcon className="nav-item-icon">
                          <FontAwesomeIcon icon={faSignOut} className="margin" />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: '2rem' }}>Sign Out</Typography>
                        </ListItem>
                      </NavLink>
                    </ListItem>
        :
                  <List>
                    <ListItem disablePadding>
                    <NavLink 
                        to="/sign-in" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                      >
                        <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faUserCircle} className="margin" />
                        </ListItemIcon>
                        <ListItemText primary='Sign In' />
                        </ListItem>
                      </NavLink>
                    </ListItem>
                    <ListItem disablePadding>
                    <NavLink 
                        to="/sign-up" 
                        className={({ isActive }) => 
                          isActive ? 'nav-item active-link' : 'nav-item'
                        }
                      >
                        <ListItem button>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faSignIn} className="margin" />
                        </ListItemIcon>
                        <ListItemText primary='Sign Up' />
                        </ListItem>
                      </NavLink>
                    </ListItem>
                </List>
              }
      </Drawer>
  )
}

export default Header;
