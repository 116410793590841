import React, { useEffect } from "react";
import AccountContent from "../../components/AccountContent";
import { user } from "../../app/selectors";
import { useSelector } from "react-redux";
import sendDataToTrackingServer from "../../features/trackingClient";

function Account() {
    const { sessionData } = useSelector(user);
    useEffect(() => {
        sendDataToTrackingServer('PERSON_PAYMENT', {
            sessionId: sessionData
        });
        // window.cdapi.setCustomerSessionId(sessionData);
        // window.cdapi.changeContext("MAKE_TRANSFER");
    }, [sessionData])

    return (
        <main id="Account">
        <h2 className="sr-only">Accounts</h2>
        <section className="content-container">
            <AccountContent description="Available" />
        </section>
        </main>
    );
}

export default Account;
