import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { BASE_URL_API, setUser } from "./features/user";
import { useSelector } from "react-redux";
import { user } from "./app/selectors";
import axios from "axios";
import HeaderToolbar from "./components/HeaderToolBar";
import Header from "./layouts/Header";
import { Box, CircularProgress } from "@mui/material";

export const RouteGuard = () => {
  const { signedIn, sessionData } = useSelector(user);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = () => {
      console.log('loading...');
      setLoading(true);
      axios
        .get(`${BASE_URL_API}user/get-user`, {
          headers: {
            Authorization: `Bearer ${sessionData}`,
          },
        })
        .then((response) => {
          setUser(response.data.user);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    };
    fetchUser();
  }, [sessionData]);

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <>
      {signedIn ? (
        <>
          <Header />
          <Box component="main" sx={{ flexGrow: 1, p: 2, height: "100%" }}>
            <HeaderToolbar />
            <Outlet />
          </Box>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
