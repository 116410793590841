import React from 'react';
import { useEffect, useState } from "react";

import { useStore, useSelector } from "react-redux";
import { user } from "../../app/selectors";
import { getUser, updateUser } from "../../features/user";

import PATTERN_NAME from "../../utils/patternName";
import sendDataToTrackingServer from '../../features/trackingClient';
import Loader from "../../components/Loader";

function User() {
  const store = useStore();
  const { firstName, lastName } = useSelector(user);

  let [edit, setEdit] = useState(false);

  useEffect(() => {
    getUser(store)
  }, [store]);

  useEffect(() => {
    sendDataToTrackingServer('HOME_PAGE');
    // window.cdapi.changeContext("CHANGE_DETAILS");
  }, [])

  // Display loader while user details is null
  if (firstName === null) {
    return (
      <main id="User">
        <Loader />
      </main>
    );
  } else {
    return (
      <main id="User">
        {edit ? (
          <div className="header">
            <h1>Welcome back</h1>
            <div className="edit-input">
              <input
                type="text"
                id="first-name"
                placeholder={firstName}
                pattern={PATTERN_NAME}
                required
              />
              <input
                type="text"
                id="last-name"
                placeholder={lastName}
                pattern={PATTERN_NAME}
                required
              />
            </div>
            <p className="errorText hide">Enter valid names</p>
            <div className="button-container">
              <button
                onClick={() => updateUser(store, setEdit)}
                id="saveButton">
                Save
              </button>
              <button onClick={() => setEdit(false)}>Cancel</button>
            </div>
          </div>
        ) : (
          <div className="header">
            <h1>
              Welcome back
              <br />
              {`${firstName} ${lastName} !`}
            </h1>
            <div className="button-container">
              <button onClick={() => setEdit(true)}>Edit Name</button>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default User;
