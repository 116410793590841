import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store"; // Update the path to your Redux setup file
import { PersistGate } from "redux-persist/integration/react";
import { alpha } from '@mui/material/styles';


import "./styles/main.scss";

// Pages
import Error404 from "./pages/404";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import User from "./pages/User";
import Account from "./pages/Account";

import reportWebVitals from "./reportWebVitals";
import { RouteGuard } from "./RouteGuard";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  palette: {
    primary: {
      main: '#30A8F2',
      light100: '#86c2e7',
      light200: '#8ccaf7',
      light300: '#b2defd',
    },
    secondary: {
      main: '#F2B705',
    },
    error: {
      main: '#ff0000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
        },
        containedSecondary: {
          // Define the hover style
          '&:hover': {
            color: 'white',
            backgroundColor: alpha('#86c2e7', 0.5), // Lighter version of primary color
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          '& input': { // Targeting the input element
            fontSize: '1.5em', // Set the desired font size
          }
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          fontSize: '1.5em', // Set the desired font size
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem', // Set your desired font size
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Adjust this value for a more specific roundness for Cards
        },
      },
    },
    // Override styles for MUI DataGrid
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // borderRadius: '8px', // Adjust this value for a more specific roundness for DataGrid cells
        },
      },
    },
  },
  // You can also define typography, breakpoints, and other theme-specific properties here
});

root.render(
  <ThemeProvider theme={theme}>
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Box sx={{ display: 'flex', height: '100vh' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route path="user" element={<RouteGuard />}>
                <Route index element={<User />} /> {/* Use index for the default nested route */}
              </Route>
              <Route path="account" element={<RouteGuard />}>
                <Route index element={<Account />} /> {/* Use index for the default nested route */}
              </Route>
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Box>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
